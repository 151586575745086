import { GetServerSidePropsContext } from 'next'
import Router, { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import { Routes } from '../models/enums/Routes.enum'
import { getAuthToken, removeAuthToken } from '../storage'
import jwt_decode from 'jwt-decode'
import { JwtDecode } from '../models/types'
import { getRedirectRouteByRole } from './getRedirectRouteByRole'
import { getAllowedRolesByPage } from './getAllowedRolesByPage'

export type RequireAuthCookie = (
  ctx: GetServerSidePropsContext,
  redirectTo?: string, // Where to redirect in case user has incorrect auth.
  inverse?: boolean // If true, users that do have a cookie will be redirected.
) => void

export const requireAuthCookie: RequireAuthCookie = (
  { req, res },
  redirect = Routes.LOGIN,
  inverse = false
) => {
  const cookie = req?.headers?.cookie
  const hasAuthToken = cookie?.includes('authToken=')

  const shouldRedirect = inverse ? hasAuthToken : !hasAuthToken

  if (shouldRedirect) {
    res?.writeHead(303, { Location: redirect })
    res?.end()
  }
}

function useRoutePermission(needsAuth: boolean, route: string) {
  const token = getAuthToken()
  const message = needsAuth
    ? 'Você precisa fazer login para ver essa página.'
    : null

  // Server must be allowed to render static page with no redirection
  const isClientSide = typeof window !== 'undefined'

  // If the permissions dont match, we must redirect the client
  const shouldRedirect = isClientSide && needsAuth !== Boolean(token)

  useEffect(() => {
    if (shouldRedirect) {
      toast.error(message)
      Router.replace(route)
    } else {
      verifyPermission(route, token)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return shouldRedirect
}

const verifyPermission = (route: string, token?: string) => {
  if (typeof window === 'undefined' || !token) return

  const { permissions }: JwtDecode = jwt_decode(token)
  const allowed = [
    'OPERATOR',
    'VOJO',
    'MANAGER',
    'CUSTOMER_SUCCESS',
    'THIRD_PARTY_CUSTOMER_SUCCESS',
    'OI_THIRD_PARTY_OPERATOR',
    'MARKETING',
  ]

  const hasPermission = permissions.find(role => allowed.includes(role))

  if (hasPermission) return

  toast.error('Você não possui permissão para utilizar a aplicação')
  removeAuthToken()
  Router.replace(route)
}

const useRolePermission = (allowedRoles?: string[]) => {
  const token = getAuthToken()
  if (typeof window === 'undefined' || !token || !allowedRoles) return

  const { permissions }: JwtDecode = jwt_decode(token)
  const hasPermission = permissions.find(role => allowedRoles.includes(role))

  if (hasPermission) return

  const route = getRedirectRouteByRole(permissions)
  return route
}

const useRequireAuthed = () => useRoutePermission(true, Routes.LOGIN)
const useForbidAuthed = () => useRoutePermission(false, Routes.JOBROLES)
const useAllowedRole = (allowedRoles?: string[]) =>
  useRolePermission(allowedRoles)

export const RequireAuthed: React.FC = ({ children }) => {
  const { pathname } = useRouter()
  const allowedRoles = getAllowedRolesByPage(pathname)
  const route = useAllowedRole(allowedRoles)
  const redirects = useRequireAuthed()

  if (route) {
    Router.replace(route)
    return null
  }

  return redirects ? null : <>{children}</>
}

export const ForbidAuthed: React.FC = ({ children }) => {
  const redirects = useForbidAuthed()
  return redirects ? null : <>{children}</>
}
