import * as Yup from 'yup'
import { password } from '@/lib/validation'
import { emptyField, invalidEmail } from '@/lib/validation/ErrorMessages'
import { FieldValues } from 'react-hook-form'

export interface LoginFormData extends FieldValues {
  username: string
  password: string
}

const loginFormSchema = Yup.object().shape({
  username: Yup.string().email(invalidEmail).required(emptyField),
  password,
})

export default loginFormSchema
