export const invalidEmail = 'Por favor, digite um e-mail válido'

export const invalidCnpj = 'Por favor, digite um CNPJ válido'

export const invalidUrl = 'Por favor, digite um link válido'

export const emptyField = 'Este campo não pode estar vazio'

export const notANumber = 'Este campo aceita apenas números'

export const positiveNumber = 'Deve ser maior que 0'

export const passwordsDontMatch =
  'As senhas não correspondem. Por favor, verifique e tente novamente'

export const fieldTooShort = 'Sua senha deve conter pelo menos 6 caracteres'

export const invalidCellphone = 'Digite um número de celular válido'

export const atLeastOneOption = 'É necessário selecionar pelo menos uma opção'
