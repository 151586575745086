import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import { FocusEventHandler } from 'react'
import { Controller } from 'react-hook-form'
import { ControlRHF } from '@/lib/models/types'

export type FormTextFieldProps = TextFieldProps & {
  className?: string
  control: ControlRHF
  defaultValue?: string
  name: string
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
}

export const FormTextField: React.FC<FormTextFieldProps> = ({
  className,
  control,
  defaultValue,
  name,
  onBlur: onBlurToContext,
  ...rest
}) => {
  return (
    <Controller
      {...{ defaultValue, name, control }}
      render={({ field: { onBlur, onChange, value } }) => (
        <TextField
          {...rest}
          onBlur={e => {
            onBlur()
            onBlurToContext && onBlurToContext(e)
          }}
          {...{
            className,
            onChange,
            value,
          }}
        />
      )}
    />
  )
}
