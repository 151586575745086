import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@material-ui/core'
import Icon from '../Icon'
import { FC, ReactNode } from 'react'
import useToggle from '@/lib/hooks/useToggle'
import { Controller } from 'react-hook-form'
import { ControlRHF } from '@/lib/models/types'

export type PasswordInputProps = Omit<TextFieldProps, 'type'> & {
  name: string
  startAdornment?: ReactNode
  variant?: 'standard' | 'filled' | 'outlined'
  disabled?: boolean
  defaultValue?: string
  control: ControlRHF
}

export const PasswordInput: FC<PasswordInputProps> = ({
  name,
  label,
  required,
  control,
  placeholder,
  variant = 'standard',
  startAdornment,
  helperText,
  error,
  defaultValue,
  ...rest
}) => {
  const startAdornmentComponent = startAdornment && (
    <InputAdornment position="start">{startAdornment}</InputAdornment>
  )

  // Visibility toggler
  const [showPassword, toggleShowPassword] = useToggle(false)
  const Visibility: FC = () => {
    const icon = showPassword ? 'VisibilityOn' : 'VisibilityOff'
    return <Icon {...{ icon }} />
  }

  const endAdornment = (
    <InputAdornment position="end">
      <IconButton onClick={toggleShowPassword}>
        <Visibility />
      </IconButton>
    </InputAdornment>
  )

  const textFieldProps = {
    type: showPassword ? 'text' : 'password',
    variant,
    label,
    placeholder,
    required,
    helperText: error || helperText,
    error: Boolean(error),
    inputProps: {
      id: name,
    },
    InputProps: {
      startAdornment: startAdornmentComponent,
      endAdornment,
    },
    ...rest,
  }

  return (
    <Controller
      {...{ defaultValue, name, control }}
      render={({ field }) => <TextField {...textFieldProps} {...field} />}
    />
  )
}
