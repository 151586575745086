import styled from 'styled-components'
import { EdukIncoming } from '@/components/Logo/EdukIncoming'
import StylableNextLink from '@/components/StylableNextLink'
import { H1 } from '@/components/Typography'
import { Alert } from '@material-ui/lab'

export const Logo = styled(EdukIncoming)`
  width: 10rem;
  margin: auto;
`

export const Link = styled(StylableNextLink)`
  font-weight: 700;
  font-size: 0.875em;
  line-height: 1.5em;
  letter-spacing: 0.01em;
  margin-top: -0.5em;
  color: ${({ theme }) => theme.edukRed};
`

export const FormTitle = styled(H1)`
  text-align: center;
  margin: 8px 0 5px 0;
`

export const RequestError = styled(Alert)`
  border: none;
  color: red;
  padding: 0px;

  div {
    padding: 0px;
  }
`
export const YupError = styled.p`
  color: red;
  margin: -15px 10px 0 10px;
  font-size: 12px;
`
