import LoginView from '@/views/Login'
import { FormLayout } from '@/layouts'
import { PageWithLayout } from '@/lib/models/types/layouts'
import { ForbidAuthed } from '@/lib/utils/authUtils'

const LoginPage: PageWithLayout = () => {
  return (
    <ForbidAuthed>
      <LoginView />
    </ForbidAuthed>
  )
}

LoginPage.Layout = FormLayout

export default LoginPage
