export function getAllowedRolesByPage(page: string): string[] | undefined {
  const pathName = page.split('/')[1]

  if (['job-roles', 'candidates', 'dashboard'].includes(pathName))
    return ['OPERATOR', 'MANAGER', 'VOJO']

  if (['companies', 'accesses'].includes(pathName)) return ['OPERATOR', 'VOJO']

  if (page.includes('users'))
    return ['CUSTOMER_SUCCESS', 'VOJO', 'THIRD_PARTY_CUSTOMER_SUCCESS']

  if (page.includes('oi-register-user')) return ['OI_THIRD_PARTY_OPERATOR']

  if (page.includes('notifications')) return ['MARKETING']

  return undefined
}
