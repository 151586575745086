import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ErrorMessage } from '@hookform/error-message'

import { useAuth } from '@/lib/context/AuthContext'

//TODO : After changing all forms, change the original component and use it
import { FormTextField } from '@/components/TextField/FormTextField'
import { PasswordInput } from '@/components/PasswordInput/index'

import { Button } from '@material-ui/core'

import schema, { LoginFormData } from '@/lib/validation/Login'

import { Logo, Link, FormTitle, RequestError, YupError } from './styles'

const Login: React.FC = () => {
  const { login, isLoginLoading } = useAuth()
  const [errorMessage, setErrorMessage] = useState(null)

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LoginFormData>({ resolver: yupResolver(schema), mode: 'onBlur' })

  async function onSubmit(data: LoginFormData) {
    login(data).catch(e => {
      setErrorMessage(e.response.data.error.message)
    })
  }

  const isClientSide = typeof window !== 'undefined'

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Logo width="10rem" height="auto" />
      <FormTitle>Entre na sua conta</FormTitle>
      {errorMessage && (
        <RequestError severity="error" variant="outlined">
          {errorMessage}
        </RequestError>
      )}
      <FormTextField
        name="username"
        control={control}
        label="E-mail"
        variant="outlined"
        id="username"
        error={Boolean(errors?.username)}
      />
      <ErrorMessage errors={errors} name="username" as={<YupError />} />
      {isClientSide && (
        <PasswordInput
          name="password"
          control={control}
          label="Senha"
          variant="outlined"
          id="password"
          error={Boolean(errors?.password)}
        />
      )}
      <ErrorMessage errors={errors} name="password" as={<YupError />} />

      <Link href="/password-recovery">Esqueceu a senha?</Link>
      <Button
        disabled={isLoginLoading}
        variant="contained"
        color="primary"
        type="submit"
        name="entrar"
      >
        Entrar
      </Button>
    </form>
  )
}

export default Login
